/* eslint-disable @typescript-eslint/no-restricted-imports */
import "react-toastify/dist/ReactToastify.css";

import {
  CustomUserProperties,
  getBrowser,
  SharedEventName,
} from "@uniswap/analytics-events";
import { useWeb3React } from "@web3-react/core";
import { getDeviceId, sendAnalyticsEvent, Trace, user } from "analytics";
import ErrorBoundary from "components/ErrorBoundary";
// import Loader from 'components/Icons/LoadingSpinner'
import Loading from "components/Loading";
import NavBar, { PageTabs } from "components/NavBar";
import { useFeatureFlagsIsLoaded } from "featureFlags";
// import { useInfoPoolPageEnabled } from "featureFlags/flags/infoPoolPage";
import { useAtom } from "jotai";
import { useBag } from "nft/hooks/useBag";
import {
  lazy,
  Suspense,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { shouldDisableNFTRoutesAtom } from "state/application/atoms";
import { useRouterPreference } from "state/user/hooks";
import { StatsigProvider, StatsigUser } from "statsig-react";
import styled from "styled-components";
import { SpinnerSVG } from "theme/components";
import DarkModeQueryParamReader from "theme/components/DarkModeQueryParamReader";
import { useIsDarkMode } from "theme/components/ThemeToggle";
import { flexRowNoWrap } from "theme/styles";
import { Z_INDEX } from "theme/zIndex";
import { STATSIG_DUMMY_KEY } from "tracing";
import { getEnvName, isBrowserRouterEnabled } from "utils/env";
import { getDownloadAppLink } from "utils/openDownloadApp";
import { getCurrentPageFromLocation } from "utils/urlRoutes";
import { getCLS, getFCP, getFID, getLCP, Metric } from "web-vitals";

// High-traffic pages (index and /swap) should not be lazy-loaded.
import Landing from "./Landing";
// import Swap from './Swap'

const AppChrome = lazy(() => import("./AppChrome"));
// const NftExplore = lazy(() => import('nft/pages/explore'))
// const Collection = lazy(() => import('nft/pages/collection'))
// const Profile = lazy(() => import('nft/pages/profile'))
// const Asset = lazy(() => import('nft/pages/asset/Asset'))
const AddLiquidity = lazy(() => import("pages/AddLiquidity"));
const BuyAYLT = lazy(() => import("pages/BuyAYLT"));
// const Stake = lazy(() => import("pages/Stake"));
const WithdrawAYLT = lazy(() => import("pages/WithdrawAYLT"));
const RedirectDuplicateTokenIds = lazy(
  () => import("pages/AddLiquidity/redirects")
);
const RedirectDuplicateTokenIdsV2 = lazy(
  () => import("pages/AddLiquidityV2/redirects")
);
// const MigrateV2 = lazy(() => import('pages/MigrateV2'))
// const MigrateV2Pair = lazy(() => import('pages/MigrateV2/MigrateV2Pair'))
const NotFound = lazy(() => import("pages/NotFound"));
// const Pool = lazy(() => import("pages/Pool"));
// const PositionPage = lazy(() => import("pages/Pool/PositionPage"));
// const PoolV2 = lazy(() => import("pages/Pool/v2"));
// const PoolDetails = lazy(() => import("pages/PoolDetails"));
// const PoolFinder = lazy(() => import("pages/PoolFinder"));
// const RemoveLiquidity = lazy(() => import("pages/RemoveLiquidity"));
// const RemoveLiquidityV3 = lazy(() => import("pages/RemoveLiquidity/V3"));
const TokenDetails = lazy(() => import("pages/TokenDetails"));
const Tokens = lazy(() => import("pages/Tokens"));
const CrossChainSwap = lazy(() => import("pages/CrossChainSwap"));
// const Vote = lazy(() => import('pages/Vote'))

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: ${({ theme }) => theme.navHeight}px 0px 5rem 0px;
  align-items: center;
  flex: 1;
`;

const MobileBottomBar = styled.div`
  z-index: ${Z_INDEX.sticky};
  position: fixed;
  display: flex;
  bottom: 0;
  right: 0;
  left: 0;
  width: calc(100vw - 16px);
  justify-content: space-between;
  padding: 0px 4px;
  height: ${({ theme }) => theme.mobileBottomBarHeight}px;
  background: ${({ theme }) => theme.surface1};
  border: 1px solid ${({ theme }) => theme.surface3};
  margin: 8px;
  border-radius: 20px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: none;
  }
`;

const HeaderWrapper = styled.div<{ transparent?: boolean }>`
  ${flexRowNoWrap};
  background-color: ${({ theme, transparent }) =>
    !transparent && theme.surface1};
  border-bottom: ${({ theme, transparent }) =>
    !transparent && `1px solid ${theme.surface3}`};
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: ${Z_INDEX.dropdown};
`;

// this is the same svg defined in assets/images/blue-loader.svg
// it is defined here because the remote asset may not have had time to load when this file is executing
const LazyLoadSpinner = () => (
  <SpinnerSVG
    width="94"
    height="94"
    viewBox="0 0 94 94"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M92 47C92 22.1472 71.8528 2 47 2C22.1472 2 2 22.1472 2 47C2 71.8528 22.1472 92 47 92"
      stroke="#2172E5"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SpinnerSVG>
);

export default function App() {
  const isLoaded = useFeatureFlagsIsLoaded();
  const [shouldDisableNFTRoutes, setShouldDisableNFTRoutes] = useAtom(
    shouldDisableNFTRoutesAtom
  );

  const browserRouterEnabled = isBrowserRouterEnabled();
  const location = useLocation();
  const { hash, pathname } = location;
  const currentPage = getCurrentPageFromLocation(pathname);
  const isDarkMode = useIsDarkMode();
  const [routerPreference] = useRouterPreference();
  const [scrolledState, setScrolledState] = useState(false);
  // const infoPoolPageEnabled = useInfoPoolPageEnabled();

  useEffect(() => {
    window.scrollTo(0, 0);
    setScrolledState(false);
  }, [pathname]);

  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get("disableNFTs") === "true") {
      setShouldDisableNFTRoutes(true);
    } else if (searchParams.get("disableNFTs") === "false") {
      setShouldDisableNFTRoutes(false);
    }
  }, [searchParams, setShouldDisableNFTRoutes]);

  useEffect(() => {
    // User properties *must* be set before sending corresponding event properties,
    // so that the event contains the correct and up-to-date user properties.
    user.set(CustomUserProperties.USER_AGENT, navigator.userAgent);
    user.set(CustomUserProperties.BROWSER, getBrowser());
    user.set(
      CustomUserProperties.SCREEN_RESOLUTION_HEIGHT,
      window.screen.height
    );
    user.set(CustomUserProperties.SCREEN_RESOLUTION_WIDTH, window.screen.width);

    // Service Worker analytics
    const isServiceWorkerInstalled = Boolean(
      window.navigator.serviceWorker?.controller
    );
    const isServiceWorkerHit = Boolean((window as any).__isDocumentCached);
    const serviceWorkerProperty = isServiceWorkerInstalled
      ? isServiceWorkerHit
        ? "hit"
        : "miss"
      : "uninstalled";

    const pageLoadProperties = { service_worker: serviceWorkerProperty };
    sendAnalyticsEvent(SharedEventName.APP_LOADED, pageLoadProperties);
    const sendWebVital =
      (metric: string) =>
      ({ delta }: Metric) =>
        sendAnalyticsEvent(SharedEventName.WEB_VITALS, {
          ...pageLoadProperties,
          [metric]: delta,
        });
    getCLS(sendWebVital("cumulative_layout_shift"));
    getFCP(sendWebVital("first_contentful_paint_ms"));
    getFID(sendWebVital("first_input_delay_ms"));
    getLCP(sendWebVital("largest_contentful_paint_ms"));
  }, []);

  useEffect(() => {
    user.set(CustomUserProperties.DARK_MODE, isDarkMode);
  }, [isDarkMode]);

  useEffect(() => {
    user.set(CustomUserProperties.ROUTER_PREFERENCE, routerPreference);
  }, [routerPreference]);

  useEffect(() => {
    const scrollListener = () => {
      setScrolledState(window.scrollY > 0);
    };
    window.addEventListener("scroll", scrollListener);
    return () => window.removeEventListener("scroll", scrollListener);
  }, []);

  const isBagExpanded = useBag((state) => state.bagExpanded);
  const isHeaderTransparent = !scrolledState && !isBagExpanded;

  const { account } = useWeb3React();
  const statsigUser: StatsigUser = useMemo(
    () => ({
      userID: getDeviceId(),
      customIDs: { address: account ?? "" },
    }),
    [account]
  );

  // redirect address to landing pages until implemented
  const shouldRedirectToAppInstall = pathname?.startsWith("/address/");
  useLayoutEffect(() => {
    if (shouldRedirectToAppInstall) {
      window.location.href = getDownloadAppLink();
    }
  }, [shouldRedirectToAppInstall]);

  if (shouldRedirectToAppInstall) {
    return null;
  }

  return (
    <ErrorBoundary>
      <DarkModeQueryParamReader />
      <Trace page={currentPage}>
        <StatsigProvider
          user={statsigUser}
          // TODO: replace with proxy and cycle key
          sdkKey={STATSIG_DUMMY_KEY}
          waitForInitialization={false}
          options={{
            environment: { tier: getEnvName() },
            api: process.env.REACT_APP_STATSIG_PROXY_URL,
          }}
        >
          <HeaderWrapper transparent={isHeaderTransparent}>
            <NavBar blur={isHeaderTransparent} />
          </HeaderWrapper>
          <ToastContainer />

          <BodyWrapper>
            <Suspense>
              <AppChrome />
            </Suspense>
            <Suspense fallback={<Loading />}>
              {isLoaded ? (
                <Routes>
                  <Route
                    path="/"
                    element={
                      // If we match "/" and # is defined, we are using BrowserRouter and need to redirect.
                      browserRouterEnabled && hash ? (
                        <Navigate to={hash.replace("#", "")} replace />
                      ) : (
                        <Landing />
                      )
                    }
                  />

                  <Route path="tokens" element={<Tokens />}>
                    <Route path=":chainName" />
                  </Route>
                  <Route path="tokens/:chainName/:tokenAddress" element={<TokenDetails />} />
                  {/* {infoPoolPageEnabled && (
                    <Route
                      path="pools/:chainName/:poolAddress"
                      element={<PoolDetails />}
                    />
                  )} */}
                  {/* <Route
                    path="vote/*"
                    element={
                      <Suspense fallback={<LazyLoadSpinner />}>
                        <Vote />
                      </Suspense>
                    }
                  /> */}
                  {/* <Route path="create-proposal" element={<Navigate to="/vote/create-proposal" replace />} /> */}
                  <Route
                    path="send"
                    element={
                      <Navigate
                        to={{ ...location, pathname: "/bridge" }}
                        replace
                      />
                    }
                  />
                  {/* <Route path="swap" element={<Swap />} /> */}
                  <Route path="/bridge" element={<CrossChainSwap />} />

                  <Route path="/buy-aylt" element={<BuyAYLT />} />
                  {/* <Route path="/stake" element={<Stake />} /> */}
                  <Route path="/withdraw-aylt" element={<WithdrawAYLT />} />

                  {/* <Route path="pool/v2/find" element={<PoolFinder />} />
                  <Route path="pool/v2" element={<PoolV2 />} />
                  <Route path="pool" element={<Pool />} />
                  <Route path="pool/:tokenId" element={<PositionPage />} />

                  <Route path="pools/v2/find" element={<PoolFinder />} />
                  <Route path="pools/v2" element={<PoolV2 />} />
                  <Route path="pools" element={<Pool />} />
                  <Route path="pools/:tokenId" element={<PositionPage />} />

                  <Route
                    path="add/v2"
                    element={<RedirectDuplicateTokenIdsV2 />}
                  >
                    <Route path=":currencyIdA" />
                    <Route path=":currencyIdA/:currencyIdB" />
                  </Route> */}
                  {/* <Route path="add" element={<RedirectDuplicateTokenIds />}> */}
                    {/* this is workaround since react-router-dom v6 doesn't support optional parameters any more */}
                    {/* <Route path=":currencyIdA" />
                    <Route path=":currencyIdA/:currencyIdB" />
                    <Route path=":currencyIdA/:currencyIdB/:feeAmount" />
                  </Route> */}

                  {/* <Route path="increase" element={<AddLiquidity />}>
                    <Route path=":currencyIdA" />
                    <Route path=":currencyIdA/:currencyIdB" />
                    <Route path=":currencyIdA/:currencyIdB/:feeAmount" />
                    <Route path=":currencyIdA/:currencyIdB/:feeAmount/:tokenId" />
                  </Route>

                  <Route
                    path="remove/v2/:currencyIdA/:currencyIdB"
                    element={<RemoveLiquidity />}
                  />
                  <Route
                    path="remove/:tokenId"
                    element={<RemoveLiquidityV3 />}
                  /> */}

                  {/* <Route path="migrate/v2" element={<MigrateV2 />} />
                  <Route path="migrate/v2/:address" element={<MigrateV2Pair />} /> */}

                  {/* {!shouldDisableNFTRoutes && (
                    <>
                      <Route
                        path="/nfts"
                        element={
                          <Suspense fallback={null}>
                            <NftExplore />
                          </Suspense>
                        }
                      />

                      <Route
                        path="/nfts/asset/:contractAddress/:tokenId"
                        element={
                          <Suspense fallback={null}>
                            <Asset />
                          </Suspense>
                        }
                      />

                      <Route
                        path="/nfts/profile"
                        element={
                          <Suspense fallback={null}>
                            <Profile />
                          </Suspense>
                        }
                      />

                      <Route
                        path="/nfts/collection/:contractAddress"
                        element={
                          <Suspense fallback={null}>
                            <Collection />
                          </Suspense>
                        }
                      />

                      <Route
                        path="/nfts/collection/:contractAddress/activity"
                        element={
                          <Suspense fallback={null}>
                            <Collection />
                          </Suspense>
                        }
                      />
                    </>
                  )} */}

                  <Route
                    path="*"
                    element={<Navigate to="/not-found" replace />}
                  />
                  <Route path="/not-found" element={<NotFound />} />
                </Routes>
              ) : (
                <Loading />
              )}
            </Suspense>
          </BodyWrapper>
          <MobileBottomBar>
            <PageTabs />
          </MobileBottomBar>
        </StatsigProvider>
      </Trace>
    </ErrorBoundary>
  );
}
