import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const SubscribeConatiner = styled.div`
  margin-top: 50px;
  justify-content: center;
  align-items: center;

  h4 {
    font-weight: 700;
    font-size: 36px;
    line-height: 72px;
    text-align: center;
    letter-spacing: 0.5px;
  }
  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.5px;
    padding: 0 200px;

    @media screen and (max-width: 962px) {
      padding: 0 20px;
    }
  }

  .privacy-notice {
    font-size: 12px;
    line-height: 20px;
  }
`

const Form = styled.div`
  position: relative;
  display: inline-block;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 50px;
`

const FormInput = styled.input`
  box-sizing: border-box;
  height: 45.41px;
  background: #f5f5f5;
  border: 0.810532px solid rgba(0, 10, 61, 0.12);
  border-radius: 8.10532px;
  padding-left: 10px;
  padding-right: 120px;
  width: 350px;
  font-weight: 400;
  line-height: 14px;
  align-items: center;
  color: rgba(122, 138, 160, 1);

  &:focus {
    background-color: #fff;
    outline: none;
    border: 0;
  }
`

const SubscribeButton = styled.button`
  position: absolute;
  right: 2%;
  top: 8%;
  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
  width: 100px;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  border: none;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  line-height: 8px;
  color: #fff;
  padding: 12px 20px;
  height: 40px;
  background: #0460bc;
  border-radius: 10px;

  &:active {
    background-color: #ccc;
  }

  &:after {
    content: 'Subscribe';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

const Subscribe: React.FC = () => {
  return (
    <SubscribeConatiner>
      <h4>Become an early adopter</h4>
      <p>
        Join our early users in testing our features and help us build the most secure yield protocol for tomorrow’s
        investors.
      </p>
      <Form>
        <FormInput type="email" placeholder="Enter email" />
        <SubscribeButton type="submit"></SubscribeButton>
      </Form>
      <p className="privacy-notice">
        By subscribing you accept our <Link to="/privacy-notice">Privacy Notice</Link>
      </p>
    </SubscribeConatiner>
  )
}

export default Subscribe
