import React from 'react'
import styled from 'styled-components'

import contact_us from '../../assets/images/contact-us.png'
import follow_us from '../../assets/images/follow_us.png'
import github from '../../assets/images/Github.png'
import medium from '../../assets/images/Medium_white.png'
import telegram from '../../assets/images/Telegram.png'
import twitter from '../../assets/images/Twitter.png'

const JoinOurCommunityContainer = styled.div`
    justify-content: center;
    align-items: center;
    
    .title{
        font-weight: 700;
        font-size: 36px;
        align-items: center;
        text-align: center;
        letter-spacing: 0.3px;
        // color: ${({ theme }) => theme.neutral1};
      }

    .description{
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        align-items: center;
        text-align: center;
        letter-spacing: 0.3px;
        // color: ${({ theme }) => theme.neutral1};
        padding: 20px;
    }
}
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (max-width: 962px) {
    display: grid;
  }
`

const FullBox = styled.div`
  img {
    margin-top: 40px;
    width: 370px;
    border-radius: 20px;
  }
`
const SocialsContainer = styled.ul`
  list-style-type: none;
  @media screen and (max-width: 962px) {
    margin-right: 30px;
  }
`
const SingleSocialContainer = styled.li`
  margin-top: 20px;
  height: 100px;
  background: #151c2e;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 20px;
`

const SocialDetails = styled.div`
  display: flex;
  img {
    width: 48px;
    height: 48px;
    margin-right: 20px;
  }
  span{
    margin-top: -40px;
  }
  span h5 {
    font-weight: 700;
    font-size: 18px;
    align-items: center;
    letter-spacing: 0.3px;
    color: #d6d6d6;
  }
  span p {
    font-weight: 400;
    font-size: 14px;
    align-items: center;
    letter-spacing: 0.3px;
    color: #c2c2c2;
  }
`

const JoinUs: React.FC = () => {
  return (
    <JoinOurCommunityContainer>
      <h4 className="title">Join our community</h4>
      <p className="description">
        Learn more about Astro, chat with us and have your say in the future of the Astro ecosystem
      </p>

      <Content>
        <FullBox>
          <img src={follow_us} alt="Follow Us" />
        </FullBox>
        <SocialsContainer>
          <SingleSocialContainer>
            <SocialDetails>
              <img src={telegram} alt="Telegram" />
              <span>
                <h5>Telegram</h5>
                <p>Join chat</p>
              </span>
            </SocialDetails>
          </SingleSocialContainer>
          <SingleSocialContainer>
            <SocialDetails>
              <img src={twitter} alt="Twitter" />
              <span>
                <h5>Twitter</h5>
                <p>Follow @astrofinance</p>
              </span>
            </SocialDetails>
          </SingleSocialContainer>
          <SingleSocialContainer>
            <SocialDetails>
              <img src={medium} alt="Medium" />
              <span>
                <h5>Medium</h5>
                <p>Join discussions</p>
              </span>
            </SocialDetails>
          </SingleSocialContainer>
          <SingleSocialContainer>
            <SocialDetails>
              <img src={github} alt="GitHub" />
              <span>
                <h5>GitHub</h5>
                <p>Contribute</p>
              </span>
            </SocialDetails>
          </SingleSocialContainer>
          <SingleSocialContainer>
            <SocialDetails>
              <img src={contact_us} alt="Contact us" />
              <span>
                <h5>Contact us</h5>
                <p>alyt.fi.team@gmail.com</p>
              </span>
            </SocialDetails>
          </SingleSocialContainer>
        </SocialsContainer>
      </Content>
    </JoinOurCommunityContainer>
  )
}

export default JoinUs
