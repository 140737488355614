/* eslint-disable import/no-unused-modules */
import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'

const rotateAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`
const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`
const LoadingCircle = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
`
const DotContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px dashed #ffffff;
  transform: rotate(45deg);
  animation: ${rotateAnimation} 2s linear infinite;
`
const CurvedLine = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px solid #ffffff;
  border-radius: 50%;
  clip-path: polygon(50% 0%, 100% 0%, 100% 100%);
  animation: rotate 2s linear infinite;
`
const Time = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  p {
    color: #fff;
  }
`
const TimeLeft = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #fff;
`

const Loading: React.FC = () => {
  const [elapsedTime, setElapsedTime] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setElapsedTime((prevElapsedTime) => prevElapsedTime + 1)
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  const formatTime = (timeInSeconds: number) => {
    const hours = Math.floor(timeInSeconds / 3600)
    const minutes = Math.floor((timeInSeconds % 3600) / 60)
    const seconds = timeInSeconds % 60
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`
  }
  return (
    <LoadingContainer>
      <LoadingCircle>
        <DotContainer></DotContainer>
        <CurvedLine></CurvedLine>
      </LoadingCircle>
      <Time>
        <p>Loading...</p>
        <TimeLeft>{formatTime(elapsedTime)}</TimeLeft>
      </Time>
    </LoadingContainer>
  )
}

export default Loading
