import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import deposit from "../../assets/images/deposit.png";
import stake from "../../assets/images/stake.png";
import swap from "../../assets/images/swap.png";
import Hero from "components/Hero";
import JoinOurCommunity from "../../components/JoinUs";
import Subscribe from "components/Subscribe";

const HowItWorks = styled.div`
  width: 100%;
  padding: 50px 70px;
  @media screen and (max-width: 962px) {
    padding: 10px 20px;
  }
  text-align: center;
  .title {
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    align-items: center;
    color: ${({ theme }) => theme.neutral1};
  }

  .description {
    padding: 0 200px;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.neutral1};

    @media screen and (max-width: 962px) {
      padding: 0;
    }
  }

  .content {
    width: 100%;
    overflow: hidden;
    margin-top: 50px;
  }

  .content .slider {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 962px) {
      display: block;
    }
  }

  .content .slider .slide {
    align-items: center;
    justify-content: center;
    background: rgba(17, 13, 49, 0.1);
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 20px;
    width: 350px;
    padding: 20px 50px;
    @media screen and (max-width: 962px) {
      width: 100%;
      margin-top: 20px;
      height: 450px;
    }
  }

  .content .slider .slide img {
    width: 50px;
    height: 50px;
  }

  .content .slider .slide h4 {
    margin-top: 20px;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    align-items: center;
    color: ${({ theme }) => theme.neutral1};
  }

  .content .slider .slide p {
    height: 150px;
    margin-top: 20px;
    font-weight: 400;
    font-size: 16px;
    color: ${({ theme }) => theme.neutral1};
  }

  .content .slider .slide .title {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    align-items: center;
    color: ${({ theme }) => theme.neutral1};
  }
  .content .slider .slide button {
    color: #fff;
    float: bottom;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    width: 149px;
    border: none;
    background: #0460bc;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;

    &:hover {
      background-color: transparent;
      border: 1px solid #fff;
      color: ${({ theme }) => theme.neutral1};
    }
  }
  .content .slider .slide .buttons {
    @media screen and (max-width: 962px) {
      margin-top: 0px;
    }
  }
  .content .slider .slide .stake,
  .content .slider .slide .deposit {
    margin-top: 35px;
    @media screen and (max-width: 962px) {
      margin-top: 70px;
    }
  }
`;

const Header = styled.div`
  margin-top: 100px;

  .lineNumber {
    margin-top: 80px;
  }

  hr {
    background: #fff;
    margin-left: 100px;
    margin-right: 100px;

    @media screen and (max-width: 962px) {
      display: none;
    }
  }

  .numberBox {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 962px) {
      display: grid;
      justify-content: center;
      align-items: center;
    }
  }

  .number {
    position: relative;
    top: -60px;
    text-align: center;
  }

  .number .numberIndex {
    position: relative;
    left: 60px;
    background: #110c38;
    padding: 5px 10px;
    padding-top: 15px;
    border-radius: 50%;
    width: 80px;
    height: 80px;
  }

  .number .numberIndex span {
    font-weight: 600;
    font-size: 32px;
    color: #fff;
    align-items: center;
  }

  .number .details span {
    color: ${({ theme }) => theme.neutral1};
  }

  .number .details p {
    font-size: 14px;
    color: ${({ theme }) => theme.neutral1};
    width: 200px;
  }
`;

const JoinOurCommunityContainer = styled.div`
  margin-top: 100px;
  width: 100%;
  overflow-x: hidden;
`;

const ReadyToJoin = styled.div`
  margin-top: 50px;
  justify-content: center;
  align-items: center;

  h4 {
    font-weight: 700;
    font-size: 36px;
    line-height: 72px;
    text-align: center;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.neutral1};
  }
  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.neutral1};
    padding: 0 200px;

    @media screen and (max-width: 962px) {
      padding: 0 20px;
    }
  }

  button {
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    border: none;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    line-height: 8px;
    color: #fff;
    padding: 12px 20px;
    height: 48px;
    background: #0460bc;
    border-radius: 50px;
    position: relative;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 20px;

    &:active {
      background-color: #ccc;
    }
  }

  hr {
    background: ${({ theme }) => theme.surface6};
    margin: 0 200px;
  }
`;

const Landing: React.FC = () => {
  return (
    <>
      <Hero />
      <HowItWorks>
        <Header>
          <h4 className="title">What Makes Us Unique</h4>
          <p className="description">
            What sets Astro Finance apart is our focus on governance and
            security. Our platform is built on a decentralised governance model
            that gives our users a say in how the platform evolves over time. We
            believe that true decentralisation requires active participation
            from the community, and we&lsquo;re committed to making that a
            reality.
          </p>
        </Header>
        <div className="content">
          <div className="slider">
            <div className="slide">
              <img src={deposit} alt="Deposit" />
              <h4 className="title">Deposit</h4>
              <p>
                Deposit USDC into our yield aggregator, utilizing both price-
                bullish and non-directional strategies.
              </p>
              <Link to="/buy-aylt">
                <button type="button" className="buttons deposit">
                  Go&nbsp;To&nbsp;Deposit
                </button>
              </Link>
            </div>

            <div className="slide">
              <img src={stake} alt="Staking" />
              <h4 className="title">Staking</h4>
              <p>
                Staking Astro Yield Token will allow users to receive the yield
                generated from the strategies. Rewards are paid out in WETH, and
                vest pro rata over a 30- day period.
              </p>
              <Link to="/stake">
                <button type="button" className="buttons stake">
                  Go&nbsp;To&nbsp;Staking <small>(Comming Sooon)</small>
                </button>
              </Link>
            </div>

            <div className="slide">
              <img src={swap} alt="Cross-Chain-Bridge" />
              <h4 className="title">Cross-Chain Bridge</h4>
              <p>Bridge tokens on any chain and from one chain to another.</p>
              <Link to="/bridge">
                <button type="button" className="swap">
                  Go&nbsp;To&nbsp;Bridge
                </button>
              </Link>
            </div>
          </div>
        </div>

        <Header>
          <h4 className="title">How Astro works</h4>
          <div className="container lineNumber">
            <hr />
            <div className="numberBox">
              <div className="number">
                <div className="numberIndex">
                  <span>1</span>
                </div>
                <div className="details">
                  <span>
                    <b>Deposit</b>
                  </span>
                  <p>
                    Stake any amount of your tokens to access daily staking
                    rewards
                  </p>
                </div>
              </div>
              <div className="number">
                <div className="numberIndex">
                  <span>2</span>
                </div>
                <div className="details">
                  <span>
                    <b>Receive AYLT</b>
                  </span>
                  <p>
                    Stake any amount of your tokens to access daily staking
                    rewards
                  </p>
                </div>
              </div>
              <div className="number">
                <div className="numberIndex">
                  <span>3</span>
                </div>
                <div className="details">
                  <span>
                    <b>Use in DeFi</b>
                  </span>
                  <p>
                    Stake any amount of your tokens to access daily staking
                    rewards
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Header>
      </HowItWorks>
      <JoinOurCommunityContainer>
        <JoinOurCommunity />
        <ReadyToJoin>
          <h4>Ready to join the future of asset liquidity?</h4>
          <p>
            Join Astro Finance today and start staking, earning and trading with
            ease. Don&lsquo;t wait - join the decentralized finance revolution
            today.
          </p>
          <Link to='/bridge'><button type="button">Launch App</button></Link>
          <hr />
        </ReadyToJoin>
        <Subscribe />
      </JoinOurCommunityContainer>
    </>
  );
};

export default Landing;
