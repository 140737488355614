import React, { useEffect, useRef } from "react";
import styled, { css, keyframes } from "styled-components";

import a16z from "../../assets/images/a16z.png";
import coinbase_ventures from "../../assets/images/coinbase_ventures.png";
import havilah from "../../assets/images/havilah-logo.png";
import polygon from "../../assets/images/polygon.png";
import uniswap_laps from "../../assets/images/uniswap_labs.png";
import Stake from "../StakeDisplay";
import { Link } from "react-router-dom";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
`;

const HeroContainer = styled.div`
  width: 100%;
`;

const HeroDetails = styled.div`
  text-align: center;
  align-items: center;
  justify-content: center;
`;

const HeroDetails2 = styled.div`
  margin-top: -50px;
  @media screen and (max-width: 668px) {
    margin-top: 50px;
  }
`;

const HeroTitle = styled.h3`
  text-align: center;
  padding: 0 200px;
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 80px;
  letter-spacing: 0.5px;
  @media screen and (max-width: 668px) {
    width: 100%;
    font-size: 30px;
    line-height: 40px;
    padding: 10px 20px;
  }
`;

const HeroContent = styled.p`
  padding: 0 200px;
  font-weight: 400;
  text-align: center;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.5px;

  @media screen and (max-width: 668px) {
    padding: 10px 20px;
  }
`;

const HeroButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;

const LaunchAppButton = styled.button`
  &.launchAppButton {
    background-color: #007bff;
    color: #fff;
    border: none;
    width: 100%;
    padding: 8px 16px;
    border-radius: 20px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
  }

  &:hover {
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.neutral1};
    color: ${({ theme }) => theme.neutral1};
  }

  @media screen and (max-width: 668px) {
    margin-right: 10px;
  }
`;

const ReadTheDocsButton = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  margin-left: 20px;

  &:hover {
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.neutral1};
    color: ${({ theme }) => theme.neutral1};
  }

  @media screen and (max-width: 668px) {
    margin-left: 10px;
  }
`;

const slideAnimation = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const Partners = styled.div`
  margin-top: 50px;
  padding: 10px 200px;
  @media screen and (max-width: 668px) {
    padding: 10px 0px;
    margin-top: 30px;
  }
  align-items: center;
  justify-content: center;
`;

const SliderContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

const Slider = styled.div<{ duration: number }>`
  display: flex;
  white-space: nowrap;
  animation: ${({ duration }) =>
    css`
      ${slideAnimation} ${duration}s linear infinite
    `};

  @media screen and (max-width: 668px) {
    animation: ${({ duration }) =>
      css`
        ${slideAnimation} ${duration}s linear infinite
      `};
  }
`;

const Slide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  .polygon {
    margin-left: 10px;
  }
  .a16z {
    font-size: 30px;
    font-weight: 700;
    margin-top: -8px;
  }
  span {
    color: #fff;
    margin-right: 20px;
  }
  img {
    width: 100%;
  }
`;

const OurPartners = styled.h4`
  width: 600px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.108605px;
  color: #fcfcfc;
`;

const Hero: React.FC = () => {
  const sliderWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const sliderWrapper = sliderWrapperRef.current!;
    const sliderWrapperWidth = sliderWrapper.offsetWidth;
    const slideWidth = sliderWrapperWidth / 5;

    const slideDuration = slideWidth / 100;
    sliderWrapper.style.setProperty("--slide-duration", `${slideDuration}s`);
  }, []);

  return (
    <PageContainer>
      <HeroContainer>
        <HeroDetails>
          <HeroTitle>
            Revolutionize Your Asset Liquidity with Astro Finance
          </HeroTitle>
          <HeroContent>
            Astro Finance enables decentrasied finance with low fees and fast
            transactions.
          </HeroContent>
          <HeroButtons className="mt-5">
            <Link to="/bridge" className="launchAppButton">
              <LaunchAppButton className="launchAppButton">
                Launch App
              </LaunchAppButton>
            </Link>
            <ReadTheDocsButton>Read the Docs</ReadTheDocsButton>
          </HeroButtons>
          {/* <OurPartners className="mt-5">Our Partners:</OurPartners> */}
          <Partners>
            <SliderContainer>
              <Slider duration={20} ref={sliderWrapperRef}>
                <Slide>
                  <img src={polygon} alt="polygon" />
                </Slide>
                <Slide>
                  <img src={coinbase_ventures} alt="coinbase ventures" />
                </Slide>
                <Slide>
                  <img src={uniswap_laps} alt="uniswap laps" />
                </Slide>
                <Slide>
                  <img src={a16z} alt="a16z" />
                </Slide>
                <Slide>
                  <img src={havilah} alt="havilah" />
                </Slide>
              </Slider>
            </SliderContainer>
          </Partners>
        </HeroDetails>

        {/* <HeroDetails2>
        <Stake />
      </HeroDetails2> */}
      </HeroContainer>
    </PageContainer>
  );
};

export default Hero;
